import { PiArrowLeftBold } from 'react-icons/pi';
import {
  rem,
  Flex,
  Text,
  Button,
  Box,
  Divider,
  useMantineTheme,
} from '@mantine/core';
import { centsToFormattedCurrency } from '@utilities/formatters';
import { paymentDisclaimer } from 'constants/constants';

type BillPayCreditPaymentReviewProps = {
  formattedTotal: string;
  paymentAmount: number;
  paymentAccount: string;
  feeAmount: number;
  proratedFee: number;
  isMakingPayment: boolean;
  onNextStep: () => void;
  onPreviousStep: () => void;
  feePercentage: number;
};

const BillPayCreditPaymentReview = ({
  formattedTotal,
  paymentAmount,
  paymentAccount,
  feeAmount,
  isMakingPayment,
  proratedFee,
  onNextStep,
  onPreviousStep,
  feePercentage,
}: BillPayCreditPaymentReviewProps) => {
  const detailsData = [
    {
      label: 'Payment amount',
      value: centsToFormattedCurrency(paymentAmount),
    },
    {
      label: 'Payment method',
      value: paymentAccount,
    },
    {
      label: `Fee percentage`,
      value: `${feePercentage}%`,
    },
    {
      label: `Fee amount`,
      value: centsToFormattedCurrency(feeAmount),
    },
  ];

  const theme = useMantineTheme();

  return (
    <>
      <Box
        p="xl"
        bg="neutral.0"
        sx={() => ({
          borderRadius: theme.defaultRadius,
          border: `1px solid ${theme.colors.neutral[4]}`,
        })}
      >
        <Flex gap="md" direction="column">
          {detailsData.map((item) => (
            <Flex key={item.label} justify="space-between" align="center">
              {item.label === 'Fee percentage' ? (
                <Flex direction="column">
                  <Text size="sm" c="neutral.8">
                    {item.label}
                  </Text>
                  <Text size="xs" c="neutral.8">
                    By paying early, your fees have decreased.
                  </Text>
                </Flex>
              ) : (
                <Text size="sm" c="neutral.8">
                  {item.label}
                </Text>
              )}
              {item.label === 'Fee percentage' ? (
                <Flex align="center" gap="xs">
                  <Text td="line-through" size="sm">
                    {item.value}
                  </Text>
                  <Box
                    fz={rem(12)}
                    c={theme.colors.primary[5]}
                    sx={{
                      border: `1px solid ${theme.colors.primary[5]}`,
                      borderRadius: theme.radius.sm,
                    }}
                    bg={theme.colors.primary[0]}
                    size="sm"
                    px={rem(6)}
                    py={rem(2)}
                  >
                    {proratedFee}%
                  </Box>
                </Flex>
              ) : (
                <Text size="sm">{item.value}</Text>
              )}
            </Flex>
          ))}
        </Flex>
        <Divider color="neutral.2" my="md" />
        <Text fw={500} size="sm">
          Payment total
        </Text>
        <Text size="sm">{formattedTotal}</Text>
      </Box>
      <Text mt="lg" size="sm">
        {paymentDisclaimer}
      </Text>
      <Flex mt={rem(40)} justify="space-between">
        <Button
          onClick={onPreviousStep}
          variant="neutral-outline"
          leftSection={<PiArrowLeftBold />}
        >
          Back
        </Button>
        <Button onClick={onNextStep} loading={isMakingPayment}>
          Confirm payment
        </Button>
      </Flex>
    </>
  );
};

export default BillPayCreditPaymentReview;
