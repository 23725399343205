import { useState } from 'react';
import {
  Box,
  Button,
  rem,
  Text,
  TextInput,
  LoadingOverlay,
  ActionIcon,
  Badge,
  Tooltip,
} from '@mantine/core';
import { centsToFormattedCurrency, formatCents } from '@utilities/formatters';
import FlexbaseTable from 'components/table/flexbase-table';
import { useMediaQuery } from '@mantine/hooks';
import {
  getCurrentDateFormat,
  getUTCDateFormatFromISO,
} from '@utilities/dates';
import {
  PiArrowRight,
  PiLink,
  PiMagnifyingGlass,
  PiScroll,
} from 'react-icons/pi';
import { TableColumn } from 'react-data-table-component';
import { createStyles } from '@mantine/emotion';
import { useNavigate, useParams } from 'react-router-dom';
import BillPayCreditPaymentModal from './make-bill-pay-credit-payment/bill-pay-credit-payment-modal';
import { useBillpayLOCTransactions } from 'queries/use-bill-pay';
import { LineTransaction } from '@flexbase-eng/sdk-typescript/models/components';
import { BadgeCell, EntityCell } from '@common/table/cells';

type Fee = {
  id: string;
  accountId: string;
  type: string;
  amount: number;
  currency: string;
  scale: number;
  netTerms: number;
  fundingFeePercentage: number;
  lineTransactionId: string;
  createdAt: string;
  lastModifiedAt: string;
  lastModifiedBy: string;
  sysPeriod: string;
};

//TODO: remove this once the backend type SDK is updated
export type ModifiedLineTransaction = LineTransaction & {
  id: string;
  createdAt: string;
  fees: Fee[];
  recipient: {
    name: string;
    id: string;
  };
  dueDate: string;
  netTerms: number;
  billpayInvoiceId: string;
};

const EstBadge = ({ label }: { label?: string }) => {
  return (
    <Tooltip label={label ?? 'Estimated'}>
      <Badge
        color="blue"
        variant="primary-flat"
        size="xs"
        ml={rem(8)}
        py={0}
        px={rem(4)}
      >
        Est
      </Badge>
    </Tooltip>
  );
};

const OutstandingTable = () => {
  const navigate = useNavigate();
  const { paymentId = '' } = useParams();
  const { classes, theme } = useStyles();
  const useMobileView = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const [inputText, setInputText] = useState('');
  const { data, isLoading, error } = useBillpayLOCTransactions();
  const transactions = data?.lineTransactions?.map((transaction) => ({
    ...transaction,
    createdAt: transaction.createdAt,
  })) as ModifiedLineTransaction[];

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value.toLowerCase());
  };

  const handlePayNow = (id: string) => {
    navigate(`${id}`);
  };

  const handleBillPayLinkClick = (invoiceId: string) => {
    navigate(`/bill-pay/${invoiceId}/edit?step=review-pay`);
  };

  const handleViewBillPayDashboard = () => {
    navigate('/bill-pay/bills');
  };

  const filteredData =
    transactions?.filter((transaction) => {
      if (inputText === '') {
        return transaction;
      } else {
        const parsedAmount = formatCents(transaction.amount);
        const parsedFee = centsToFormattedCurrency(
          transaction.fees?.[0]?.amount,
        );
        return (
          parsedAmount?.toString().toLowerCase().includes(inputText) ||
          parsedFee?.toString().toLowerCase().includes(inputText) ||
          getUTCDateFormatFromISO(transaction.paymentDate ?? '')
            .toLowerCase()
            .includes(inputText) ||
          getUTCDateFormatFromISO(transaction.createdAt)
            .toLowerCase()
            .includes(inputText) ||
          getUTCDateFormatFromISO(transaction.dueDate ?? '')
            .toLowerCase()
            .includes(inputText) ||
          transaction.netTerms?.toString().toLowerCase().includes(inputText) ||
          transaction.status?.toLowerCase().includes(inputText) ||
          transaction.recipient?.name?.toLowerCase().includes(inputText)
        );
      }
    }) || [];

  const openTransaction = filteredData.find((row) => row.id === paymentId);

  const columns: TableColumn<ModifiedLineTransaction>[] = [
    {
      name: 'Payment date',
      selector: (row) => row.paymentDate ?? '',
      cell: (row) =>
        row.paymentDate ? (
          getUTCDateFormatFromISO(row.paymentDate)
        ) : (
          <>
            {getCurrentDateFormat()} <EstBadge label="Estimated payment date" />
          </>
        ),
      sortable: true,
      minWidth: rem(150),
    },
    {
      name: 'Recipient',
      selector: (row) => row.recipient.name,
      cell: (row) => <EntityCell entityName={row.recipient.name} />,
      sortable: true,
      minWidth: rem(180),
    },
    {
      name: 'Terms',
      selector: (row) => row.netTerms,
      cell: (row) =>
        row.netTerms ? <BadgeCell value={`${row.netTerms} days`} /> : '',
      sortable: true,
    },
    {
      name: 'Due date',
      selector: (row) => row.dueDate ?? '',
      cell: (row) => {
        if (!row.dueDate && !row.netTerms) return '';
        if (!row.dueDate)
          return (
            <>
              {getCurrentDateFormat({ plusDays: row.netTerms })}{' '}
              <EstBadge label="Estimated due date" />
            </>
          );
        return getUTCDateFormatFromISO(row.dueDate ?? '');
      },
      sortable: true,
      minWidth: rem(150),
      id: 'dueDate',
    },
    {
      name: 'Amount due',
      selector: (row) => Number(row.amount / 100),
      sortable: true,
      format: (row) => centsToFormattedCurrency(row.amount),
      minWidth: rem(120),
    },
    {
      name: 'Fee',
      selector: (row) => row.fees?.[0]?.fundingFeePercentage ?? 0,
      format: (row) => {
        return centsToFormattedCurrency(row.fees?.[0]?.amount);
      },

      sortable: true,
    },
    {
      name: 'Bill Link',
      cell: (row) => (
        <ActionIcon
          variant="neutral-light"
          onClick={() => {
            handleBillPayLinkClick(row.billpayInvoiceId);
          }}
        >
          <PiLink size={18} />
        </ActionIcon>
      ),
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      cell: (row) => {
        const colorMap = {
          pending: theme.colors.yellow[1],
          scheduled: theme.colors.indigo[1],
          outstanding: theme.colors.orange[1],
          completed: theme.colors.green[1],
          rejected: theme.colors.red[1],
          canceled: theme.colors.pink[1],
        };
        const labelMap = {
          pending: 'Pending',
          scheduled: 'Scheduled',
          outstanding: 'Outstanding',
          completed: 'Completed',
          rejected: 'Rejected',
          canceled: 'Canceled',
        };
        return (
          <BadgeCell
            value={row.status}
            colorMap={colorMap}
            labelMap={labelMap}
          />
        );
      },
      minWidth: rem(120),
      sortable: true,
    },
    {
      name: '',
      cell: (row) =>
        row.status === 'outstanding' && (
          <Button
            variant="neutral-light"
            rightSection={<PiArrowRight size={18} />}
            onClick={() => handlePayNow(row.id)}
            size="xs"
          >
            Pay Now
          </Button>
        ),
      minWidth: rem(180),
    },
  ];

  const selectedTransaction = filteredData.find((row) => row.id === paymentId);

  if (error) {
    return (
      <Box className={classes.container}>
        <Text c="red">Error loading payments. Please try again later.</Text>
      </Box>
    );
  }

  return (
    <Box className={classes.container} pos="relative">
      <LoadingOverlay visible={isLoading} />
      <Box className={classes.header}>
        {openTransaction && selectedTransaction && (
          <BillPayCreditPaymentModal transaction={selectedTransaction} />
        )}
        <TextInput
          w={useMobileView ? '100%' : rem(368)}
          placeholder="Search"
          onChange={handleInputChange}
          leftSection={
            <PiMagnifyingGlass
              size={'1.25rem'}
              color={theme.colors.neutral[8]}
            />
          }
        />
        <Button
          variant="neutral-outline"
          leftSection={<PiScroll size={18} />}
          rightSection={<PiArrowRight size={18} />}
          onClick={handleViewBillPayDashboard}
        >
          Go to Bill Pay Dashboard
        </Button>
      </Box>
      <Box>
        <FlexbaseTable
          columns={columns}
          data={filteredData}
          pagination={filteredData && filteredData?.length > 8}
          pointerOnHover={false}
          defaultSortFieldId="dueDate"
          defaultSortAsc
        />
      </Box>
    </Box>
  );
};

export default OutstandingTable;

const useStyles = createStyles((theme) => ({
  container: {
    padding: rem(16),
    backgroundColor: theme.colors.neutral[0],
    borderRadius: theme.defaultRadius,
    border: `1px solid ${theme.colors.neutral[2]}`,
    maxWidth: rem(1365),
    margin: 'auto',
    marginTop: rem(20),
  },
  header: {
    borderBottom: 'solid',
    borderBottomColor: theme.colors.neutral[2],
    borderBottomWidth: rem(0.5),
    paddingBottom: rem(16),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 767px)': {
      flexDirection: 'column',
      gap: rem(8),
      display: 'flex',
      alignItems: 'flex-start',
    },
  },
}));
