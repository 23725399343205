import {
  InvoiceWizard,
  PaymentSource,
  useInvoiceWizard,
} from '../../invoice-wizard';
import {
  Box,
  Card,
  Flex,
  Text,
  useMantineTheme,
  Divider,
  Avatar,
} from '@mantine/core';
import ReviewBodyLoc from './review-body-loc';
import ReviewBodyRecipient from './review-body-recipient';
import ReviewBodyBankingAccount from './review-body-banking-account';
import { PiCreditCardBold } from 'react-icons/pi';

const DataRow = ({
  title,
  description,
}: {
  title: string;
  description?: string;
}) => (
  <Flex mb="md" justify="space-between">
    <Text size="sm" c="neutral.8">
      {title}
    </Text>
    <Text size="sm">{description}</Text>
  </Flex>
);

const ReviewPayStep = () => {
  const theme = useMantineTheme();
  const { state } = useInvoiceWizard();
  const { sendFrom } = state.paymentInfo || {};
  const { recipient, recipientAccount } = state || {};
  const recipientName =
    recipient.name ?? `${recipient.firstName} ${recipient.lastName}`;
  const invoiceInfo = {
    memo: state.memo,
    invoiceNumber: state.invoiceNumber,
    dueDate: state.invoiceDetails?.dueDate ?? '',
    paymentSource: state.paymentInfo?.paymentSource ?? '',
  };

  const type: Record<'wire' | 'ach', string> = {
    wire: 'Wire',
    ach: 'ACH',
  };

  const paymentSource =
    state.paymentInfo?.paymentSource === 'loc' ? 'Credit' : 'Bank account';

  const recipientAccountType = type[recipientAccount?.type as 'ach' | 'wire'];

  return (
    <InvoiceWizard.Step hideBack hideNext>
      <Card p="xl" bg="neutral.0" bd="1px solid neutral.4">
        <Text size="sm" fw={500}>
          Payment amount
        </Text>
        <Text size="sm">{state.invoiceTotal?.formatted}</Text>

        <Divider my="md" />

        <Text mb="md" fw={500} size="sm">
          Pay to
        </Text>
        <DataRow title="Recipient" description={recipientName} />
        <DataRow
          title="Invoice number"
          description={invoiceInfo.invoiceNumber}
        />

        <Divider mb="md" />

        <Text mb="md" fw={500} size="sm">
          Pay from
        </Text>
        <Flex justify="space-between">
          <Flex align="center" gap="xs">
            <Avatar size={24} bd="unset">
              <PiCreditCardBold color={theme.colors.neutral[6]} />
            </Avatar>
            <Text c="neutral.8" size="sm">
              Payment method
            </Text>
          </Flex>
          <Text size="sm" data-testid="payment-source">
            {paymentSource}
          </Text>
        </Flex>
        {state.paymentInfo?.paymentSource === PaymentSource.ACCOUNT && (
          <ReviewBodyBankingAccount sourceAccount={sendFrom} />
        )}

        <Divider my="md" />

        <ReviewBodyRecipient
          sendOn={state.paymentInfo?.sendOn}
          recipientAccount={recipientAccount}
          recipientAccountType={recipientAccountType}
        />

        {state.paymentInfo?.paymentSource === PaymentSource.LOC && (
          <ReviewBodyLoc
            invoiceTotal={state.invoiceTotal}
            sendOn={state.paymentInfo?.sendOn}
          />
        )}

        <Divider my="md" />

        <DataRow title="Memo" description={invoiceInfo.memo} />

        <Box>
          <Text c="neutral.8" size="sm">
            Internal notes
          </Text>
          <Text size="sm">{state.notes}</Text>
        </Box>
      </Card>
    </InvoiceWizard.Step>
  );
};

ReviewPayStep.stepId = 'review-pay';

export default ReviewPayStep;
